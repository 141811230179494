import type { UIResource } from "shared/data/resource";

type SuspendData = {
    type: "widget" | "editable"
}

export function getSuspendTraceData<T extends SuspendData>(resource: UIResource){
    const suspendTrace =  resource.trace.findLast(x=>x.type === "suspend");
    if (!suspendTrace){
      return null;
    }
    
    return {
      args: suspendTrace.data.args as [T],
      rootContext: suspendTrace.data.rootContext as string,
      contextName: suspendTrace.data.contextName as string,
    }
  }

export type SuspendTrace<T extends SuspendData> = Exclude<ReturnType<typeof getSuspendTraceData<T>>, null>