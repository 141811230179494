import { MarkdownWithOriginal } from "@/components/Markdown";
import { Button } from "@/components/ui/button";
import { HoverCard, HoverCardTrigger, HoverCardContent } from "@/components/ui/hover-card"
import { match } from "ts-pattern"
import { useAtomValue } from "jotai";
import { Badge } from "@/components/ui/badge"
import { Link } from "@tanstack/react-router"
import { useEffect, useMemo, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useCopyToClipboard } from "react-use";
import type { UIResource } from "shared/data/resource";
import { useGetRootParentResource, useResourceApi } from "../hooks";
import { j_resourceById, useSelectOutputTab } from "../state";

const ResourceCard = ({ id }: { id: string }) => {
    const resource = useAtomValue(j_resourceById(id));
    const badgeColor = match(resource.status)
      .with("draft", () => "bg-white" as const)
      .with("init", () => "bg-orange-400" as const)
      .with("generating", () => "bg-orange-400 animate-pulse" as const)
      .with("error", () => "bg-red-300" as const)
      .with("done", () => "bg-green-300" as const)
      .with("paused", () => "bg-yellow-300" as const)
      .with("suspended", () => "bg-yellow-300" as const)
      .exhaustive();
    const selectOutputTab = useSelectOutputTab();
    const resourceRawUrl = useMemo(()=> new URL(`/api/playgrounds/${resource.playground}/resources/${resource.id}/raw`, document.location.href).href, [resource.id, resource.playground])
    const [clipboardState, copyToClipboard] = useCopyToClipboard();
    const rootParent = useGetRootParentResource(resource.id);
    const resourceApi = useResourceApi();
    const latestAiTrace = resource.trace?.findLast(x=>x.type === "progress")
  
    return (
      <HoverCard>
      <HoverCardTrigger>
      <div className="p-2 border-2 border-accent rounded-lg">
      <div className="text-xs text-orange-400">{resource.generator?.tool}</div>
        <div className="flex flex-row items-center cursor-pointer">
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          
          <div onClick={() => selectOutputTab(id)} className="flex-grow">
            {resource.friendlyName ?? resource.id}
          </div>
          <Badge className={badgeColor}>{resource.status}</Badge>
        </div>
      </div>
      </HoverCardTrigger>
      <HoverCardContent side="right" 
                  align="end" 
                  className="w-auto p-2 flex flex-col gap-2"
                  sideOffset={10}>
        <div className="w-[250px] overflow-auto">
          <div className="rounded-md overflow-auto h-40 border-2 p-2 m-2">
            {match(resource.status)
            .with("generating", () => latestAiTrace?.data ?? "Waiting for progress...")
            .with("done", () => 
              match(resource.generator?.outputType)
              .with("wireframe", () => "Wireframe is ready")
              .with("document", () => <MarkdownWithOriginal className="">{resource.output?.data}</MarkdownWithOriginal>)
              .with("website", () =>
                <div style={{transformOrigin: "0 0", transform: "scale(0.2)", position:"relative"}}>
                <iframe style={{ position: "absolute", top:0, left: 0, width: 1100, height: 550 }} src={resourceRawUrl} />
                </div>
                 )
              .with("image", () => <img src={resource.output?.data} alt="output" />)
              .otherwise(() => resource.output?.data ?? "Ready" )
            )
            .with("error", () => resource.output?.data ?? "Error")
            .with("suspended", () => "Waiting for user input...")
            .otherwise(() => "")
            }
          {}
          </div>
        </div> 
        <div className="w-auto p-2 flex flex-row gap-2">
        <Button size="sm" variant="outline" onClick={()=>{
              copyToClipboard(resourceRawUrl)
            }}>
            <Link className="mr-2 h-4 w-4" /> {clipboardState.value ? "Copied!" : "Copy URL"}
            </Button>
            {rootParent?.id ? <Button size={"sm"} variant="outline" onClick={async ()=>{
              await resourceApi.regenerateFromCheckpoint(rootParent?.id, resource.id)
            }} >Regenerate from here</Button> : null} 
         </div>
      </HoverCardContent>
      </HoverCard>
    );
  };
  
  const TriggeredBy = ({ resource }: { resource: string }) => {
    const res = useAtomValue(j_resourceById(resource));
    const selectOutputTab = useSelectOutputTab();
    return (
      <>
        <Badge className="bg-slate-800 text-white p-1 rounded-md self-start">
          Triggered by
        </Badge>
        <Button
          className="whitespace-break-spaces text-left self-start"
          onClick={() => selectOutputTab(res.id)}
          size="sm"
          variant="link"
        >
          {res.friendlyName}
        </Button>
      </>
    );
  };
  
  export const StepsPanel = ({ resource }: { resource: UIResource }) => {
    const [scollBehavior, setScrollBehavior] = useState<"instant" | "smooth">("instant");
    const bottomRef = useRef(null as null | HTMLDivElement);
    useEffect(() => {
      requestAnimationFrame(() => {
      bottomRef.current?.scrollIntoView();
      });
    }, []);

    const allTraces = [
      ...(resource.trace ?? []).filter(
        (x) => x.type !== "data"
      ),
    ];
    useEffect(() => {
      bottomRef.current?.scrollIntoView({ behavior: scollBehavior });
      if (scollBehavior === "instant") {
        setScrollBehavior("smooth");
      }
    }, [allTraces.length]);
    // brain emoji: 🧠
    return (
      <div className="flex flex-col gap-2">
        <>
          {resource.parentResource ? (
            <TriggeredBy resource={resource.parentResource} />
          ) : null}
          <Badge className="bg-slate-800 text-white p-1 rounded-md self-start">
            Steps
          </Badge>
          {allTraces.map((x, i) =>
            match(x)
            
              .with({ type: "progress" }, (x) => (
                <div key={i} className="p-2 border-2 border-gray-800 rounded-lg">
                  {x.data}
                </div>
              ))
              .with({ type: "create-subresource" }, (x) => (
                <ErrorBoundary key={i} fallbackRender={(e)=> {
                  return <div className="p-2 border-2 border-red-800 rounded-lg">Error displaying: #{x.data.id!} ({`${e.error}`})</div>
                }}>
                <ResourceCard key={i} id={x.data.id!} />
                </ErrorBoundary>
              ))
              
              //.with({type: "log"}, (x) => x.data)
              //.with({type: "suspend"}, (x) => "Suspended")
              .otherwise(() => null)
          )}
          <div ref={bottomRef}></div>
        </>
      </div>
    );
  };
  